const baseUrl = 'https://calendly.com/scriptum-dev'

const utmParams = [
  'utm_source',
  'utm_content',
  'utm_campaign',
  'utm_term',
  'utm_medium',
]

const extractUrlParams = (paramNames) => {
  const urlParams = new URLSearchParams(window.location.search)
  return paramNames.reduce((acc, paramName) => {
    return {
      ...acc,
      [paramName]: urlParams.get(paramName) || undefined,
    }
  }, {})
}

const buildCalendlyLink = () => {
  const utm = extractUrlParams(utmParams)

  const base = new URL(baseUrl)

  for (let [key, value] of Object.entries(utm)) {
    if (value) {
      base.searchParams.append(key, value)
    }
  }

  return base.href
}

const updateCalendlyLinks = () => {
  const links = document.querySelectorAll('a.calendly')
  const ref = buildCalendlyLink()

  links.forEach((link) => {
    link.href = ref
  })
}

document.addEventListener('DOMContentLoaded', updateCalendlyLinks)
