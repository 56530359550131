const technologies = document.querySelectorAll('.technologies__item')

const toggleDetailsOpen = () => {
  const tabletWidth = 768
  const opened = window.innerWidth >= tabletWidth

  technologies.forEach((technology) => (technology.open = opened))
}

// Set initial state
toggleDetailsOpen()

window.addEventListener('resize', toggleDetailsOpen)
